<template>
    <div class="d-block d-sm-flex align-items-center mb-2">
        <h5 class="m-0 mr-5 d-flex align-items-center d-block d-sm-inline-block" v-if="title || isBackButton">
            <button class="page-header-back-btn" v-if="isBackButton" @click="backButton">
                <i class="ri-arrow-left-line"></i>
            </button>
            {{ title }}
        </h5>
        <div class="d-flex flex-grow-1 mt-2 mb-3">
            <div class="flex-grow-1 d-flex mr-2">
                <b-button class="btn-sm"
                        variant="outline-secondary"
                        v-if="isNewButton"
                        @click="$emit('new-button-click')"
                >
                    <span>
                    <i class="ri-add-line top-plus-1 mr-1"></i>
                    {{ $t('new').toUpper() }}
                    </span>
                </b-button>
                <b-button class="btn-sm ml-2"
                        variant="outline-secondary"
                        v-if="isRankButton"
                        @click="$emit('rank-button-click')"
                >
                    <span>
                    <i class="ri-sort-desc top-plus-1 mr-1"></i>
                    {{ $t('ranking').toUpper() }}
                    </span>
                </b-button>

                <b-button class="btn-sm ml-2"
                        variant="outline-secondary"
                        v-if="isImportButton"
                        @click="$emit('import-button-click')"
                >
                    <span>
                    <i class="ri-upload-line top-plus-1 mr-1"></i>
                    {{ $t('import_button').toUpper() }}
                    </span>
                </b-button>
            </div>
            <div class="mr-2" v-if="isColumns">
                <b-dropdown size="sm" variant="outline-secondary" right>
                    <template #button-content>
                        <i class="ri-layout-column-line mr-1"></i>
                        {{ $t('columns').toUpper() }}
                    </template>
                    <div class="checkbox-list width-300">
                        <div class="px-4 py-2">
                            <slot name="columns"></slot>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <div v-if="isFilter">
                <b-button
                    class="btn-sm"
                    variant="outline-secondary"
                    @click="filterDivStatusHide"
                >
                    <span>
                        <i class="ri-filter-3-line top-plus-1 mr-1"></i> {{ $t('filter').toUpper() }}
                    </span>
                </b-button>
            </div>
            <div v-if="actions && filteredActions.length>0">
                <b-dropdown id="dropdown-side-menu" right variant="outline-secondary" class="ml-2">
                    <b-dropdown-item v-for="(action,actionKey) in filteredActions"
                                    @click="action.callback"
                                    :key="actionKey"
                    >
                        {{ action.text }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: null
            },
            isNewButton: {
                type: Boolean,
                default: false
            },
            isColumns: {
                type: Boolean,
                default: false
            },
            isFilter: {
                type: Boolean,
                default: true
            },
            newButtonType: {
                type: String,
                default: 'modal'
            },
            newButtonUrl: {
                default: ''
            },
            isRankButton: {
                default: false,
                type: Boolean
            },
            isBackButton: {
                default: false,
                type: Boolean
            },
            backButton: {
                type: Function,
                default: function() {
                }
            },
            isImportButton: {
                default: false,
                type: Boolean
            },
            actions: {
                default: null,
                type: Array
            }
        },
        data() {
            return {
                filterDivStatus: true
            };
        },
        mounted() {
            this.$emit('filter:show', this.filterDivStatus);
        },
        methods: {
            filterDivStatusHide() {
                this.filterDivStatus = !this.filterDivStatus;
                this.$emit('filter-div-status', this.filterDivStatus);
            }
        },
        computed: {
            filteredActions() {
                let arr = [];
                this.actions.forEach(item => {
                    if (!item.permission || (item.permission && this.checkPermission(item.permission))) {
                        arr.push(item);
                    }
                })
                return arr;
            }
        }
    };
</script>
