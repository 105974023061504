<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <b-form-group>
                        <label>{{ $t('document_type') }}</label>
                        <parameter-selectbox v-model="form.document_type_id"
                                             code="student_document_types"
                                             :disabled="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="documentDueDate" rules="required"
                                        v-slot="{ valid, errors }">
                        <b-form-group :label="$t('document_due_date')">
                            <select-date v-model="form.document_due_date"
                                         :validationError="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </ValidationObserver>
        <b-col cols="12" class="pl-0">
            <b-button
                @click="updateForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </b-col>
    </div>
</template>

<script>
//Other
import { ValidationProvider, ValidationObserver } from "vee-validate";
//Components
import StudentProgramService from "@/services/StudentProgramService";

export default {
    components: {
        ValidationProvider, ValidationObserver
    },
    props: {
        formData: {
            type: Object,
            required: true
        },
        studentProgram: {
            type: Object,
            default: null,
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.missingDocuments = this.studentProgram.missing_documents
    },
    data() {
        return {
            formLoading: false,
            form: { ...this.formData }
        };
    },
    watch: {
        formData: {
            handler(newData) {
                this.form = { ...newData };
            },
            deep: true
        },
        studentProgram: {
            handler(value) {
                this.missingDocuments = value.missing_documents;
            },
            immediate: true,
        }
    },
    methods: {
        async updateForm() {
            if (this.checkPermission("studentprogram_updatemissingdocuments")) {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;

                    let missing_documents = [];

                    missing_documents = this.missingDocuments.filter(
                        item => item.document_type_id !== this.form.document_type_id
                    );

                    missing_documents.push({
                        document_type_id: this.form.document_type_id,
                        document_due_date: this.form.document_due_date,
                    });

                    StudentProgramService.updateMissingDocument(this.formId, missing_documents)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e);
                        })
                }
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"));
            }
        }
    }
};
</script>
