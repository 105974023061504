<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="type_of_disability" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('disability_type')">
                            <parameter-selectbox v-model="formData.type_of_disability"
                                                 code="disabled_types"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="disability_rate" v-slot="{valid, errors}">
                        <b-form-group :label="$t('disability_rate')">
                            <div class="border rounded p-3 text-center">
                                <b-form-input type="range"
                                              min="0"
                                              max="100"
                                              v-check-min-max-value
                                              step="1"
                                              v-model="formData.disability_rate"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <span>{{ '%' + formData.disability_rate }}</span>
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="surgery_illness_history" v-slot="{valid, errors}">
                        <b-form-group :label="$t('surgery_or_discomfort')">
                            <b-form-textarea v-model="formData.surgery_illness_history"
                                             :class="errors[0] ? 'is-invalid':''"
                                             rows="2"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="medicines" v-slot="{valid, errors}">
                        <b-form-group :label="$t('used_drugs')">
                            <b-form-textarea v-model="formData.medicines"
                                             :class="errors[0] ? 'is-invalid':''"
                                             rows="2"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        },
        created() {
            this.formId = this.studentProgram.id;
            this.formData = {
                type_of_disability: this.studentProgram.type_of_disability,
                disability_rate: this.studentProgram.disability_rate ? Math.floor(this.studentProgram.disability_rate) : 0,
                surgery_illness_history: this.studentProgram.surgery_illness_history,
                medicines: this.studentProgram.medicines
            }
        },
        data() {
            return {
                formId: null,
                formData: {},
                formProcess: false
            }
        },
        methods: {
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    let formData = {
                        type_of_disability: this.formData.type_of_disability,
                        disability_rate: this.formData.disability_rate ? this.formData.disability_rate : 0,
                        surgery_illness_history: this.formData.surgery_illness_history,
                        medicines: this.formData.medicines
                    }
                    this.formProcess = true;
                    StudentProgramService.updateHealth(this.formId, formData)
                                         .then(response => {
                                             this.$emit('updateFormSuccess', true);
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch((e) => {
                                             this.showErrors(e, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formProcess = false;
                                         })
                }
            }
        }
    }
</script>

