import Api from '@/services/Index'

const setDefault = () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
}

const getAll = (config) => {
    setDefault()
    return Api.get('/disciplines', config)
}

const getExcell = (config) => {
    setDefault()
    return Api.get('/disciplines/excel-export', config)
}

const getStudentAll = (id) => {
    setDefault()
    return Api.get('/disciplines/student-program/' + id)
}

const get = (id) => {
    setDefault()
    return Api.get('/disciplines/' + id)
}

const getFiles = (id) => {
    setDefault()
    return Api.get('/disciplines/files/' + id)
}

const downloadFile = (uuid) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/disciplines/download/' + uuid, {responseType: 'arraybuffer'})
}

const store = (data) => {
    setDefault()
    return Api.post('/disciplines', data)
}

const storeFile = (data) => {
    setDefault()
    return Api.post('/disciplines/upload', data)
}

const update = (id, data) => {
    setDefault()
    return Api.put('/disciplines/' + id, data)
}

const del = (id) => {
    setDefault()
    return Api.delete('/disciplines/' + id)
}

const exportExcel= (config) =>{
    setDefault()
    return Api.get('/disciplines/excel-export',{...config,responseType:'arraybuffer'})
}

export default {
    getAll,
    getExcell,
    getStudentAll,
    get,
    getFiles,
    downloadFile,
    store,
    storeFile,
    update,
    del,
    exportExcel
}
