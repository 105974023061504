<template>
    <div>
        <h5 class="mt-1 mb-3">{{ $t('general_information').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('student_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('student_number') }}</label>
                                <div>{{ studentProgram.student_number ? studentProgram.student_number : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('name') }}</label>
                                <div>{{ studentProgram.name ? studentProgram.name : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('surname') }}</label>
                                <div>{{ studentProgram.surname ? studentProgram.surname : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('student_status') }}</label>
                                <div>{{ studentProgram.student_status_text || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('semester_status') }}</label>
                                <div>{{ studentProgram.semester_status_name }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('gno') }}</label>
                                <div>{{ studentProgram.cgpa ? studentProgram.cgpa : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6" v-if="studentProgram.application_number">
                            <div class="label-as-input-div">
                                <label>{{ $t('application_number') }}</label>
                                <div>{{ studentProgram.application_number }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card :header="$t('university_informations')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('affiliated_curriculum') }}</label>
                                <div>{{ studentProgram.curriculum_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('class') }}</label>
                                <div>{{ studentProgram.class_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('major_type') }}</label>
                                <div>{{ studentProgram.major_type_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('completed_credit') }}</label>
                                <div>{{ studentProgram.credits ? $t('credit_x', {credit: parseInt(studentProgram.credits)}) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_semesters_studied') }}</label>
                                <div>{{ studentProgram.number_of_semesters ? studentProgram.number_of_semesters : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('leaving_date') }}</label>
                                <div>{{ studentProgram.leaving_date ? this.toLocaleDate(studentProgram.leaving_date) : '-' }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card :header="$t('registration_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_date') }}</label>
                                <div>{{ studentProgram.registration_date ? this.toLocaleDate(studentProgram.registration_date) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_season') }}</label>
                                <div>
                                    {{ studentProgram.registration_semester_academic_year ? studentProgram.registration_semester_academic_year : '-' }},
                                    {{ studentProgram.registration_semester ? this.getSemester(studentProgram.registration_semester) : '-' }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('registration_type') }}</label>
                                <div>{{ studentProgram.registration_type_text || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('legal_presence_type') }}</label>
                                <div>{{ studentProgram.legal_presence_type || "-" }}</div>
                            </div>
                        </b-col>
                        <!-- <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('scholarship_rate') }}</label>
                                <div v-if="studentProgram.scholarship_rate==null">{{ $t("scholor_ship_rate_ration")}}</div>
                                <div v-else>{{"ÖSYM %"+studentProgram.scholarship_rate}}</div>
                            </div>
                        </b-col> -->
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('school_name') }}</label>
                                <div>{{ studentProgram.faculty_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('department_name') }}</label>
                                <div>{{ studentProgram.department_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('program_name') }}</label>
                                <div>{{ studentProgram.program_name || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('SEVIS ID') }}</label>
                                <div>{{ studentProgram.sevis_id  || "-" }}</div>
                            </div>
                        </b-col>
                    </b-row>

                </b-card>
                <b-card :header="$t('other_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('advisor') }}</label>
                                <div>{{ studentProgram.advisor_name ? studentProgram.advisor_name : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('email_of_advisor') }}</label>
                                <div>{{ studentProgram.email_of_advisor || "-" }}</div>
                            </div>
                        </b-col>
<!--                        <b-col cols="12">-->
<!--                            <div class="label-as-input-div">-->
<!--                                <label>{{ $t('thesis_advisor') }}</label>-->
<!--                                <div>{{ studentProgram.thesis_and_project_advisor ? studentProgram.thesis_and_project_advisor : '-' }}</div>-->
<!--                            </div>-->
<!--                        </b-col>-->
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('success_status') }}</label>
                                <div>{{ studentProgram.success_status || "-" }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('discount_per_credit') }}</label>
                                <div>{{ studentProgram.sevis_id  || "-" }}</div>
                            </div>
                        </b-col>


                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    export default {
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        }
    }
</script>

