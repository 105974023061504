<template>
  <div>
    <ValidationObserver ref="formModalValidate">
      <div class="border rounded p-4 mb-3">
        <b-row>
          <b-col sm="4">
            <ValidationProvider name="registration_semester_id" rules="required" v-slot="{valid, errors}">
              <b-form-group :label="$t('academic_year_of_registration')">
                <semesters-selectbox
                    v-model="formData.registration_semester_id"
                    :validateError="errors[0]"/>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="registration_date" rules="required" v-slot="{valid, errors}">
              <b-form-group :label="$t('registration_date')">
                <selectDate v-model="formData.registration_date"
                            :validationError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
              <ValidationProvider name="registration_type" rules="required" v-slot="{valid, errors}">
                  <b-form-group :label="$t('registration_type')">
                      <parameter-selectbox v-model="formData.registration_type"
                                           code="registration_types"
                                           :validateError="errors[0]"
                      />
                  </b-form-group>
              </ValidationProvider>
            <ValidationProvider name="advisor_id" rules="" v-slot="{valid, errors}">
              <b-form-group :label="$t('advisor')">
                <staff-auto-complete v-model="formData.advisor_id"
                                     :valueObject="advisorObject"
                                     :validate-error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
              <ValidationProvider name="minor_program_code" rules="" v-slot="{valid, errors}">
                  <b-form-group :label="$t('minor_program')">
                      <program-selectbox v-model="formData.minor_program_code" major_type="Y"
                                         :validateError="errors[0]"
                      />
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <ValidationProvider name="student_status" rules="required" v-slot="{valid, errors}">
              <b-form-group :label="$t('student_status')">
                <parameter-selectbox v-model="formData.student_status"
                                     code="student_statuses"
                                     :validateError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="semester_status" v-slot="{valid, errors}">
              <b-form-group :label="$t('semester_status')">
                <parameter-selectbox v-model="formData.semester_status"
                                     code="semester_statuses"
                                     :validateError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
              <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                  <b-form-group :label="$t('class')">
                      <parameter-selectbox v-model="formData.class"
                                           code="classes"
                                           :validateError="errors[0]"
                      />
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="4">

            <!--                        <ValidationProvider name="curriculum_id" rules="required" v-slot="{valid, errors}">-->
            <!--                            <b-form-group :label="$t('curriculum')">-->
            <!--                                <curriculum-selectbox v-model="formData.curriculum_id"-->
            <!--                                                      :program_code="studentProgram.program_code"-->
            <!--                                                      :validateError="errors[0]"-->
            <!--                                />-->
            <!--                            </b-form-group>-->
            <!--                        </ValidationProvider>-->
            <ValidationProvider name="legal_presence_type" rules="" v-slot="{valid, errors}">
              <b-form-group :label="$t('legal_presence_type')">
                <parameter-selectbox code="legal_presence_types" v-model="formData.legal_presence_type"
                                     :validateError="errors[0]"/>
              </b-form-group>
            </ValidationProvider>
                <ValidationProvider name="credit_transfer" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('credit_transfer')">
                        <yes-no-selectbox v-model="formData.credit_transfer"
                                             :validateError="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="re_enrollment" rules="" v-slot="{valid, errors}">
                    <b-form-group :label="$t('re_enrollment')">
                        <yes-no-selectbox v-model="formData.re_enrollment"
                                          :validateError="errors[0]"
                        />
                    </b-form-group>
                </ValidationProvider>
              <ValidationProvider name="credit_type" rules="" v-slot="{valid, errors}">
                  <b-form-group :label="$t('credit_type')">
                      <parameter-selectbox code="credit_types" v-model="formData.credit_type"
                                        :validateError="errors[0]"
                      />
                  </b-form-group>
              </ValidationProvider>
              <ValidationProvider name="change_of_program" rules="" v-slot="{valid, errors}">
                  <b-form-group :label="$t('change_of_program')">
                      <yes-no-selectbox v-model="formData.change_of_program"
                                        :validateError="errors[0]"
                      />
                  </b-form-group>
              </ValidationProvider>
            </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col md="4" class="flex-column d-flex">
          <div class="mb-2">
            <h6 class="mb-0">{{ $t('graduation_information') }}</h6>
          </div>
          <div class="border rounded equal-height p-4 mb-4">
            <ValidationProvider name="graduation_semester_id"
                                :rules="formData.student_status == '102' ? 'required':''"
                                v-slot="{valid, errors}">
              <b-form-group :label="$t('graduation_semester')">
                <semesters-selectbox v-model="formData.graduation_semester_id"
                                     :without-years="true"
                                     :validateError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="graduation_date"
                                :rules="formData.student_status == '102' ? 'required':''"
                                v-slot="{valid, errors}">
              <b-form-group :label="$t('graduation_date')">
                <selectDate v-model="formData.graduation_date"
                            :validationError="errors[0]"
                />
              </b-form-group>

            </ValidationProvider>
            <!--                        <ValidationProvider name="graduation_decision_number"-->
            <!--                                            v-slot="{valid, errors}">-->
            <!--                            <b-form-group :label="$t('graduation_decision_number')">-->
            <!--                                <b-form-input v-model="formData.graduation_decision_number"-->
            <!--                                              :class="errors[0] ? 'is-invalid':''"-->
            <!--                                />-->
            <!--                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
            <!--                            </b-form-group>-->
            <!--                        </ValidationProvider>-->
          </div>
        </b-col>
        <b-col md="4" class="flex-column d-flex">
          <div class="mb-2">
            <h6 class="mb-0">{{ $t('diploma_information') }}</h6>
          </div>
          <div class="border rounded equal-height p-4 mb-4">
            <ValidationProvider name="diploma_status" v-slot="{valid, errors}">
              <b-form-group :label="$t('diploma_status')">
                <parameter-selectbox v-model="formData.diploma_status"
                                     code="diploma_statuses"
                                     :validateError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="diploma_date" v-slot="{valid, errors}">
              <b-form-group :label="$t('diploma_date')">
                <selectDate v-model="formData.diploma_date"
                            :validation-error="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <!--                        <ValidationProvider name="diploma_number" v-slot="{valid, errors}">-->
            <!--                            <b-form-group :label="$t('diploma_number')">-->
            <!--                                <b-form-input v-model="formData.diploma_number"-->
            <!--                                              :class="errors[0] ? 'is-invalid':''"-->
            <!--                                />-->
            <!--                                <b-form-invalid-feedback v-if="errors[0]"-->
            <!--                                                         v-html="errors[0]"/>-->
            <!--                            </b-form-group>-->
            <!--                        </ValidationProvider>-->
          </div>
        </b-col>
        <b-col md="4" class="flex-column d-flex">
          <div class="mb-2">
            <h6 class="mb-0">{{ $t('leaving_information') }}</h6>
          </div>
          <div class="border rounded equal-height p-4 mb-4">
            <ValidationProvider name="leaving_reason"
                                rules=""
                                v-slot="{valid, errors}">
              <b-form-group :label="$t('reason_for_leaving')">
                <parameter-selectbox v-model="formData.leaving_reason"
                                     code="leaving_reasons"
                                     :validateError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="leaving_semester_id"
                                rules=""
                                v-slot="{valid, errors}">
              <b-form-group :label="$t('leaving_semester')">
                <semesters-selectbox v-model="formData.leaving_semester_id"
                                     :validatError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="leaving_date" rules=""
                                v-slot="{valid, errors}">
              <b-form-group :label="$t('leaving_date')">
                <selectDate v-model="formData.leaving_date"
                            :disabled="isDisabledLeavingData"
                            :validationError="errors[0]"
                />
              </b-form-group>
            </ValidationProvider>
          </div>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center">
        <b-button variant="primary"
                  @click="updateForm">
          {{ $t('save') }}
        </b-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import CurriculumSelectbox from '@/components/interactive-fields/CurriculumSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import SelectDate from '@/components/interactive-fields/SelectDate';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import YesNoSelectbox from '@/components/interactive-fields/YesNoSelectbox';

// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import moment from "moment";


export default {
  components: {
    CurriculumSelectbox,
    ParameterSelectbox,
    SelectDate,
    SemestersSelectbox,
    StaffAutoComplete,
    YesNoSelectbox,

    ValidationProvider,
    ValidationObserver
  },
  props: {
    studentProgram: {
      type: Object,
      default: null
    }
  },
    watch:{

    },
  created() {this.formId = this.studentProgram.id;
    this.formData = {
      // 1
      registration_date: this.studentProgram.registration_date,
      registration_type: this.studentProgram.registration_type,
      registration_semester_id: this.studentProgram.registration_semester_id,
      advisor_id: this.studentProgram.advisor_id,
      student_status: this.studentProgram.student_status,
      semester_status: this.studentProgram.semester_status,
      class: this.studentProgram.class,
      curriculum_id: this.studentProgram.curriculum_id,


      irregular: this.studentProgram.irregular,
      legal_presence_type: this.studentProgram.legal_presence_type,
        credit_transfer: this.studentProgram.credit_transfer,
        re_enrollment: this.studentProgram.re_enrollment,
        change_of_program: this.studentProgram.change_of_program,
        credit_type: this.studentProgram.credit_type,
        minor_program_code: this.studentProgram.minor_program_code,

      // 2
      graduation_semester_id: this.studentProgram.graduation_semester_id,
      graduation_date: this.studentProgram.graduation_date,
      graduation_decision_number: this.studentProgram.graduation_decision_number,

      // 3
      leaving_reason: this.studentProgram.leaving_reason,
      leaving_date: this.studentProgram.leaving_date,
      leaving_semester_id: this.studentProgram.leaving_semester_id,

      // 5
      diplama_status: this.studentProgram.diplama_status,
      diploma_number: this.studentProgram.diploma_number,
      diploma_date: this.studentProgram.diploma_date
    }
    if (this.studentProgram.advisor_id) {
      this.advisorObject = {
        value: this.studentProgram.advisor_id,
        text: this.studentProgram.advisor_name
      }
    }
  },
  data() {
    return {
      formId: null,
      formData: {},
      formLoading: false,
      isDisabledLeavingData:false,
      advisorObject: {}
    }
  },
  methods: {
      async updateForm() {
          const isValid = await this.$refs.formModalValidate.validate();
          if (isValid && this.formId) {
              const registration_date = moment(this.formData.registration_date).format('MM/DD/YYYY')
              this.formData.registration_date = registration_date
              /*if (!['102','104','105','106','109','107','203','204','206','207','209'].includes(this.formData.student_status)){
                  delete this.formData.leaving_date
                  delete this.formData.graduation_date
                  this.formData.registration_date = registration_date
              }else{
                  const leaving_date = moment(this.formData.leaving_date).format('MM/DD/YYYY')
                  const graduation_date = moment(this.formData.graduation_date).format('MM/DD/YYYY')
                  this.formData.leaving_date = leaving_date
                  this.formData.graduation_date = graduation_date
                  this.formData.registration_date = registration_date
              }*/
              StudentProgramService.update(this.formId, this.formData)
                  .then(response => {
                      this.$emit('updateFormSuccess', true);
                      this.$toast.success(this.$t('api.' + response.data.message));
                  })
                  .catch(e => {
                      this.showErrors(e, this.$refs.formModalValidate)
                  })
          }
      }
  }

}
</script>

