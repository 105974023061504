<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="passport_number" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input v-model="formData.passport_number"
                                          :class="errors[0] ? 'is-invalid':''"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="formData.name"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="surname" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="formData.surname"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <b-form-group :label="$t('middle_name')">
                        <b-form-input v-model="formData.middle_name"
                        >
                        </b-form-input>
                    </b-form-group>
                    <ValidationProvider name="gender" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="formData.gender" :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="father_name" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('father_name')">
                            <b-form-input v-model="formData.father_name"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="mother_name" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('mother_name')">
                            <b-form-input v-model="formData.mother_name"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date_of_birth')" class="position-relative">
                            <v-date-picker
                                v-model="formData.birthdate"
                                locale="en"
                                is-expanded
                                :masks="{ input: 'DD-MM-YYYY'}"
                                :popover="{ 'visibility': 'click' }">
                                <template
                                    v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input
                                            :readonly="true"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            :state="errors[0] ? false : null"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                variant="outline-secondary"
                                                class="btn-40"
                                                disabled
                                            >
                                                <i class="ri-calendar-line"></i
                                                ></b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-input-group>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="birthplace" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('birthplace')">
                            <b-form-input v-model="formData.birthplace"
                                          :class="errors[0] ? 'is-invalid':''">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="nationality_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('nationality')">
                            <country-selectbox v-model="formData.nationality_code"
                                               :validateError="errors[0]"
                                               valueType="code"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" v-slot="{valid, errors}">
                        <b-form-group>
                            <label>{{ $t('city') }}</label>
                            <city-selectbox v-model="formData.city_id"
                                            :validateError="errors[0]"></city-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="district_id" v-slot="{valid, errors}">
                        <b-form-group>
                            <label>{{ $t('district') }}</label>
                            <district-outline-selectbox v-model="formData.district_id"
                                                        :city_id.sync="formData.city_id"
                                                        :validateError="errors[0]"></district-outline-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="race" v-slot="{valid, errors}">
                        <b-form-group>
                            <label>{{ $t('race') }}</label>
                            <parameter-selectbox
                                code="races"
                                v-model="formData.race"
                                :validateError="errors[0]">

                            </parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";

// Services
import StudentProgramService from "@/services/StudentProgramService"

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import moment from "moment";

export default {
    components: {
        CitySelectbox,
        CountrySelectbox,
        DistrictOutlineSelectbox,
        GenderSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        studentProgram: {
            type: Object,
            default: null,
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.formData = {
            passport_number: this.studentProgram.passport_number,
            name: this.studentProgram.name,
            surname: this.studentProgram.surname,
            gender: this.studentProgram.gender,
            race: this.studentProgram.race,

            father_name: this.studentProgram.father_name,
            mother_name: this.studentProgram.mother_name,
            birthdate: this.studentProgram.birthdate,
            birthplace: this.studentProgram.birthplace,
            middle_name: this.studentProgram.middle_name,

            nationality_code: this.studentProgram.nationality_code,
            city_id: this.studentProgram.city_id,
            district_id: this.studentProgram.district_id,
        }
    },
    data() {
        return {
            formId: null,
            formData: {},
            formProcess: false
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                let formData = {
                    ...this.formData
                }
                formData.birthdate = formData.birthdate ? moment(formData.birthdate).format('MM/DD/YYYY') : null

                this.formProcess = true
                StudentProgramService.updateIdentity(this.formId, formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formProcess = false
                    })
            }
        }
    }
}
</script>

