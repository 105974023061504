<template>
    <div>
        <div class="border rounded p-4 mb-4">
            <div class="d-flex flex-column flex-md-row">
                <div class="mr-2">
                    <b-form-group :label="$t('type')">
                        <b-form-select v-model="type"
                                       :options="typeOptions"
                                       class="h-40"
                        />
                    </b-form-group>
                </div>
                <div class="flex-grow-1 mr-2">
                    <b-form-group :label="$t('upload_file')">
                        <div class="d-flex custom-file-upload">
                            <b-form-file v-model="file"
                                         :placeholder="$t('select_file')"
                                         :drop-placeholder="$t('drop_file')"
                                         ref="fileInput"
                                         accept="application/pdf,.docx"
                            />
                            <b-button variant="outline-secondary" @click="()=>{$refs.fileInput.$el.childNodes[0].click();}">
                                {{ $t('browse') }}
                            </b-button>
                        </div>
                    </b-form-group>
                </div>
                <div>
                    <label class="d-none d-md-block">&nbsp;</label>
                    <b-button variant="primary"
                              @click="uploadFile()"
                    >
                        <i class="ri-add-line"></i> <span class="d-block d-md-none">{{ $t('add') }}</span>
                    </b-button>
                </div>
            </div>
        </div>

        <h5>{{ $t('uploaded_files') }}</h5>
        <div v-if="isHasFile">
            <div v-for="(file,fileKey) of files" :key="file.type">
                <div class="d-flex mb-1" v-if="file.file">
                    <div class="label-as-input flex-grow-1 mr-2">
                        {{ $t(file.type) }}
                    </div>
                    <!--
                    <div class="label-as-input flex-grow-1 mr-2">
                        {{ $t(file['file'].file_name) }}
                    </div>
                    -->
                    <b-button variant="primary"
                              class="btn-40"
                              @click="downloadFile(file['file'].uuid,file['file'].file_name)">
                        <i class="ri-download-line"></i>
                    </b-button>
                </div>
            </div>
        </div>
        <no-result v-else/>
    </div>
</template>

<script>
    // Component
    import NoResult from '@/components/elements/NoResult'

    // Services
    import DisciplinesService from '@/services/DisciplinesService'

    export default {
        components: {
            NoResult
        },
        props: {
            formId: {
                type: Number
            }
        },
        computed: {
            isHasFile() {
                if (this.files && this.files.length > 0) {
                    return this.files.filter(c => c.file != null).length ? true : false
                }
                else {
                    return false;
                }
            }
        },
        data() {
            return {
                files: [],

                // Upload Form
                file: null,
                type: null,
                typeOptions: [
                    {value: null, text: ''},
                    {value: 'disciplinary_committee_decision', text: this.$t('disciplinary_committee_decision')},
                    {value: 'reporter_analysis_report', text: this.$t('reporter_analysis_report')},
                    {value: 'reporter_assignment_letter', text: this.$t('reporter_assignment_letter')},
                    {value: 'compass_dates', text: this.$t('compass_dates')},
                    {value: 'investigation_report', text: this.$t('investigation_report')},
                    {value: 'witness_complainant_statement', text: this.$t('witness_complainant_statement')},
                    {value: 'written_and_oral_defense_report', text: this.$t('written_and_oral_defense_report')},
                    {value: 'invitation_to_the_defense', text: this.$t('invitation_to_the_defense')},
                    {value: 'complaint_petition', text: this.$t('complaint_petition')}
                ]
            }
        },
        created() {
            this.getFiles();
        },
        methods: {
            getFiles() {
                this.files = null
                DisciplinesService.getFiles(this.formId)
                                  .then((response) => {
                                      this.files = response.data
                                  })
                                  .catch((e) => {
                                      this.showErrors(e)
                                  })
            },
            uploadFile() {
                let formData = new FormData();
                formData.append('discipline_id', this.formId)
                formData.append('type', this.type)
                formData.append('file', this.file)

                DisciplinesService.storeFile(formData)
                                  .then(response => {
                                      this.$toast.success(this.$t('api.' + response.data.message))
                                      this.getFiles();
                                  })
                                  .catch((e) => {
                                      this.showErrors(e)
                                  })
            },
            downloadFile(uuid, fileName) {
                DisciplinesService.downloadFile(uuid)
                                  .then(res => {
                                      this._downloadFile(res, fileName);
                                  })
                                  .catch((e) => {
                                      this.showErrors(e)
                                  })
            }
        }
    }
</script>
