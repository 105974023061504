<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('missing_documents').toUpper() }}</h5>
        <b-table bordered
                 striped
                 responsive
                 show-empty
                 :items="missingDocuments"
                 :fields="missingDocumentsFields"
                 class="mb-4 table-dropdown border rounded">
            <template #cell(document_type_name)="row">
                {{ getLocaleText(row.item, 'document_type_name') }}
            </template>
            <template #cell(document_due_date)="row">
                {{ row.item.document_due_date | dateFormat }}
            </template>
            <template #cell(actions)="row">
               <div class="d-flex">
                   <b-button variant="outline-secondary" class="btn-40" size="xs" @click="loadData(row.item)"><i class="ri-edit-box-line"></i></b-button>
                   <b-button variant="outline-danger" class="btn-40 ml-2" size="sm" @click="formDelete(row.index)"><i class="ri-delete-bin-2-line"></i></b-button>
               </div>
            </template>
            <template #empty="scope">
                <div class="text-center py-2">{{ $t('no_result_1') }}</div>
            </template>
        </b-table>
        <b-button variant="primary"
                  @click="createFormShow"
                  v-if="checkPermission('studentprogram_updatemissingdocuments') && editMode">
            {{ $t('add') }}
        </b-button>
        <CommonModal ref="createFormModal"
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_updatemissingdocuments') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('add').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <create-form :studentProgram="studentProgram"
                                       @createFormSuccess="createFormSuccess()"
                                       v-if="formProcess=='create'"
                />
            </template>
        </CommonModal>
        <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true"  @bHideModalHeaderClose="clearFormData()" v-if="checkPermission('studentprogram_updatemissingdocuments') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpperCase() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :formData="formData"
                             :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"/>
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Components
    import CommonModal from '@/components/elements/CommonModal';

    // Page
    import UpdateForm from './UpdateForm';
    import CreateForm from './CreateForm';

    import StudentProgramService from "@/services/StudentProgramService";

    export default {
        components: {
            CommonModal,
            CreateForm,
            UpdateForm,
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null,
                studentId: null,
                missingDocuments: [],
                missingDocumentsFields: [
                    {
                        key: 'document_type_name',
                        label: this.$t('missing_document').toUpper(),
                        sortable: true
                    },
                    {
                        key: 'document_due_date',
                        label: this.$t('document_due_date').toUpper(),
                        tdClass: 'text-center',
                        thClass: 'text-center',
                        sortable: true
                    },
                    {
                        key: 'actions', label: '', sortable: false, class: 'w-40'
                    }
                ],
                formId: null,
                formData: [],
            }
        },
        watch: {
            studentProgram: {
                handler(value) {
                    this.missingDocuments = value.missing_documents;
                },
                immediate: true
            }
        },
        created() {
            this.studentId = this.$route.params.id;
            this.missingDocuments = this.studentProgram.missing_documents
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            createFormShow() {
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.clearFormData();
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            },
            updateMissingDocument(formData) {
                StudentProgramService.updateMissingDocument(this.studentId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e);
                    })
            },
            formDelete(index) {
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.missingDocuments.splice(index, 1);

                            this.formData = [];
                            if(this.missingDocuments && this.missingDocuments.length > 0) {
                                this.missingDocuments.forEach(item => {
                                    this.formData.push({
                                        'document_type_id': item.document_type_id,
                                        'document_due_date': item.document_due_date,
                                    });
                                });
                            }

                            this.updateMissingDocument(this.formData);
                        }
                    });
            },
            loadData(data){
                this.formProcess = "update";
                this.formId = this.studentId;
                this.formData = data;
                this.$refs.updateFormModal.$refs.commonModal.show();
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            },
        }
    }
</script>

