<template>
    <div>
        <page-header :title="$t('logs').toUpper()"
                     :isColumns="false"
                     :isFilter="false">
            <template v-slot:columns>
                <div class="mb-1" v-for="(column,key) in datatable.columns">
                    <b-form-checkbox
                        :id="'checkbox-'+key"
                        :name="'checkbox-'+key"
                        :value="false"
                        :unchecked-value="true"
                        v-model="column.hidden"
                        v-if="column.field!='buttons'"
                    >
                        {{ column.label }}
                    </b-form-checkbox>
                </div>
            </template>
        </page-header>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :edit-mode="editMode"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   :show-title="false"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
    </div>
</template>
<script>
    // Component
    import Datatable from '@/components/datatable/Datatable'
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import PageHeader from '@/components/page/PageHeader'

    // Page
    import moment from "moment"


    // Services
    import StudentNoteService from '@/services/StudentNoteService';

    // Other
    import qs from 'qs';
    import StudentService from "@/services/StudentService";

    export default {
        components: {
            Datatable,
            DatatableFilter,
            PageHeader,
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: this.$t('type'),
                            field: 'type',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                        },
                        {
                            label: this.$t('from'),
                            field: 'from',
                            sortable: false,
                            thClass: 'text-left',
                            tdClass: 'text-left',
                            hidden: false,
                            html: true,
                            formatFn: (value) => {
                                return '<div>' + this.nl2br(value) + '</div>'
                            }
                        },
                        {
                            label: this.$t('to'),
                            field: 'to',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                        },
                        {
                            label: this.$t('created_at'),
                            field: 'created_at',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                        },
                        {
                            label: this.$t('created_by'),
                            field: 'user_name_surname',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                formId: null,
                formProcess: null
            }
        },
        created() {
            this.filterClear();
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    student_number: this.studentProgram.student_number
                }
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : ''
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows()
            },
            getRows() {
                this.datatable.showTable = true
                this.datatable.isLoading = true

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return StudentService.studentLogs(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data
                                             this.datatable.total = response.data.pagination.total
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         })
            },

            // Clear
            clearFormData() {
                this.formId = null
                this.formProcess = null
            },

            // Show
            showFormShow(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.formModal.$refs.commonModal.show()
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            StudentNoteService.del(id)
                                              .then(response => {
                                                  this.$toast.success(this.$t('api.' + response.data.message))
                                                  this.getRows()
                                              })
                                              .catch(error => {
                                                  this.$toast.error(this.$t('api.' + error.data.message))
                                              })
                        }
                    })
            }
        }
    }
</script>

